import React, { useState } from 'react';
import { Card, Col, Empty, Flex, Pagination, Row, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { decodeResponse } from '../../helpers/apiUtils';
import { FetchData } from '../../network/fetchData';
import DeleteModal from '../common/DeleteModal';
import { deleteData } from '../../network/deleteData';
import FilterTable from '../common/filtersTables/FilterTable';
import AddCertificate from './AddCertificate';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import UpdateCertificate from './UpdateCertificate';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFileModal from '../common/UploadFile';

const ListCertificates: React.FC = () => {
  const [editCertificateData, setEditCertificateData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | 'delete' | 'upload' | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 12;
  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id ?? null);
    setEditCertificateData(data ?? null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteCertificateParams = {
    mode: 'certificate',
    type: 'delete',
    id: selectedRowId,
  };

  const fetchCertificatesParams = {
    mode: 'certificate',
    type: 'get',
    language_id: currentLang === 'ar' ? 1 : currentLang === 'en' ? 2 : null,
  };

  const { data: listCertificates, isLoading, isFetching } = useQuery(
    [queryKeys.listCertificates, fetchCertificatesParams.language_id, searchTerm],
    () => FetchData({ ...fetchCertificatesParams, name: searchTerm }),
    { keepPreviousData: true }
  );

  const decodedCertificatesData = decodeResponse(listCertificates);
  const currentItems: any[] = decodedCertificatesData?.data?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderCertificates = () => {
    if (isLoading || isFetching) {
      return (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      );
    }

    if (!decodedCertificatesData?.data || decodedCertificatesData?.data.length === 0) {
      return <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />;
    }

    return (
      <>
        <Row
          gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 15 }}
        >
          {currentItems.map((item: any) => (
            <Col key={item.id} className='mb-4' xl={6} lg={6} md={12} sm={12} xs={24}>
              <Card
                title={item?.name}
                actions={[
                  <Link
                    to='/certificate/data'
                    className='text-[#ED1D23]'
                    state={{ certificate_data: item }}
                    key='view'
                  >
                    <EyeOutlined />
                  </Link>,
                  <EditOutlined key='edit' onClick={() => showModal('update', item.id, item)} />,
                  <DeleteOutlined key='delete' style={{ color: 'red' }} onClick={() => showModal('delete', item.id)} />,
                ]}
              >
                <Flex align='center'>
                  <img alt={item?.name} src={item.media} className='w-full responsive-img' />
                </Flex>
              </Card>
            </Col>
          ))}
        </Row>
        <Flex justify='end'>
          <Pagination
            current={currentPage}
            total={decodedCertificatesData?.data?.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </Flex>
      </>
    );
  };

  return (
    <>
      <FilterTable
        isUpload={false}
        buttonText={<FormattedMessage id='add_certificate' />}
        tabName={<FormattedMessage id='certificate' />}
        onClick={() => showModal('add')}
        items={[{ title: <FormattedMessage id='certificate' /> }]}
        onSearch={setSearchTerm}
        isFilter={false}
      />

      <UploadFileModal
        visible={isModalVisible && modalAction === 'upload'}
        onClose={closeModal}
        mode={'assessment_advice'}
        confirmationHeader={''}
        successMessage={''}
        queryKey={queryKeys.listAdvice}
      />

      <AddCertificate
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
      />

      <UpdateCertificate
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        certificateData={editCertificateData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteCertificateParams}
        confirmationHeader={<FormattedMessage id='certificate' />}
        queryKey={queryKeys.listCertificates}
        onClose={closeModal}
      />

      {renderCertificates()}
    </>
  );
};

export default ListCertificates;
import React, { useState } from 'react';
import { Space } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../services/react-query/queryKeys';
import { decodeResponse, decryptData } from '../../../../helpers/apiUtils';
import { FetchData } from '../../../../network/fetchData';
import DeleteModal from '../../../common/DeleteModal';
import { deleteData } from '../../../../network/deleteData';
import FilterTable from '../../../common/filtersTables/FilterTable';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../../common/UploadFile';
import CustomTable from '../../../common/CustomTable';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

interface ExamexaminableProps {
  exam_scheduling_data: any;
}

interface DataType {
  examinable_type: string;
  examinable: any;
  id: React.Key;
  name: string;
  email: string;
}

const Examexaminable: React.FC<ExamexaminableProps> = ({ exam_scheduling_data }) => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "examinables_exam",
    type: "delete",
    id: selectedRowId
  };
  const values = {
    mode: "examinee",
    type: "get",
    exam_schedule_id: exam_scheduling_data?.id,
  };

  const { data: listExaminees, isLoading, isFetching } = useQuery(
    [queryKeys.listExaminees, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listExaminees);
  const decryptedData = decodedResponse?.data.map((item: {
    examinable: {
      corporate: any; name: any; email: any; phone: any;
    };
  }) => {
    return {
      ...item,
      examinable: {
        ...item?.examinable,
        name: decryptData(item?.examinable?.name),
        email: decryptData(item?.examinable?.email),
        phone: decryptData(item?.examinable?.phone),
        corporateEmail: decryptData(item?.examinable?.corporate?.email),
        corporatePhone: decryptData(item?.examinable?.corporate?.phone),
      },
    };
  });

  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id={'name'} />,
      dataIndex: ['examinable', 'name'],
      key: 'name',
    },
    {
      title: <FormattedMessage id={'email'} />,
      key: 'email',
      render: (record: DataType) => record.examinable_type === "Batch" ? record.examinable.corporateEmail : record.examinable.email,
    },
    {
      title: <FormattedMessage id={'phone'} />,
      key: 'phone',
      render: (record: DataType) => record.examinable_type === "Batch" ? record.examinable.corporatePhone : record.examinable.phone,
    },
    {
      key: 'operation',
      width: 100,
      render: (record: DataType) => (
        <Space>
          <Link to="/examinee/data" className='text-gray-400 mx-1' state={{ examinee_data: record }}><EyeOutlined /></Link>
          <DeleteOutlined style={{ color: "red" }} onClick={() => showModal('delete', record.id)} />
        </Space>
      ),
    },
  ];

  const items = [
    {
      title: <FormattedMessage id='exams' />,
      href: '/exams',
    },
    {
      title: <FormattedMessage id='examinables' />,
      href: '',
    }
  ];

  return (
    <>
      <FilterTable
        items={items}
        onSearch={(value) => setSearchTerm(value)}
        isAdded={false}
        isUpload={false}
        isFilter={false}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"e-learning"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listELearnings} />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id='exam_examinables' />}
        queryKey={queryKeys.listExams}
        onClose={closeModal}
      />

      <CustomTable scroll={{ x: 'max-content' }} isLoading={isLoading} isFetching={isFetching} columns={columns} data={decryptedData} />
    </>
  );
}

export default Examexaminable;

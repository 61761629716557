import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";

export const StoreData = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("/IBDL", body);
  return data;
});


export const addELearning = catchAsync(async (body) => {
  const response = await axiosInstance.post(`/IBDL`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status !== 200) {
    console.log(response);
    throw new Error(response.AxiosError.response?.data?.message || 'An error occurred while processing your request');
  }

  return response.data;
});



export const addCertificate = catchAsync(async (body) => {
  const response = await axiosInstance.post(`/IBDL`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status !== 200) {
    throw new Error(response.data.message || 'An error occurred while processing your request');
  }

  return response.data;
});

export const addAssessment = catchAsync(async (body) => {
  const response = await axiosInstance.post(`/IBDL`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status !== 200) {
    throw new Error(response.data.message || 'An error occurred while processing your request');
  }

  return response.data;
});

export const addFormData = catchAsync(async (body) => {
  const response = await axiosInstance.post(`/IBDL`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status !== 200) {
    throw new Error(response.data.message || 'An error occurred while processing your request');
  }

  return response.data;
});

import React, { useState } from 'react';
import { Space } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../services/react-query/queryKeys';
import { decodeResponse, decryptItem } from '../../../../helpers/apiUtils';
import { FetchData } from '../../../../network/fetchData';
import FilterTable from '../../../common/filtersTables/FilterTable';
import { EyeOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import CustomTable from '../../../common/CustomTable';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

interface ExamexaminableProps {
  examineeData: any;
}

interface DataType {
  examinable_type: string;
  examinable: any;
  id: React.Key;
  name: string;
  email: string;
}

const BatchExaminee: React.FC<ExamexaminableProps> = ({ examineeData }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const values = {
    mode: "batch",
    type: "get_user_batch",
    batch_id: examineeData?.examinable?.id
  };

  const { data: listUserBatchs, isLoading, isFetching } = useQuery(
    [queryKeys.listUserBatchs, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listUserBatchs);
  const decryptedData = decodedResponse?.data?.map(decryptItem) ?? [];

  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id={'name'} />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id={'email'} />,
      dataIndex: 'email',
    },
    {
      title: <FormattedMessage id={'phone'} />,
      dataIndex: 'phone',
    },
    {
      key: 'operation',
      width: 100,
      render: (record: DataType) => (
        <Space>
          <Link to="/examinee/data" className='text-gray-400 mx-1' state={{ examinee_data: record, batch: examineeData }}><EyeOutlined /></Link>
        </Space>
      ),
    },
  ];

  const items = [
    {
      title: <FormattedMessage id='exams' />,
      href: '/exams',
    },
    {
      title: <FormattedMessage id='user_batch' />,
      href: '',
    }
  ];

  return (
    <>
      <FilterTable
        items={items}
        onSearch={(value) => setSearchTerm(value)}
        isAdded={false}
        isUpload={false}
        isFilter={false}
      />

      <CustomTable scroll={{ x: 'max-content' }} isLoading={isLoading} isFetching={isFetching} columns={columns} data={decryptedData} />
    </>
  );
}

export default BatchExaminee;

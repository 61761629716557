import React, { useState } from 'react';
import { Card, Col, Modal, Row, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { UpOutlined, DownOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFileModal from '../../common/UploadFile';
import CustomExpandedTable from '../../common/CustomExpandedTable';
import { roleLabelsAr, roleLabelsEn } from '../../../enum/questionType';

interface DataType {
  id: React.Key;
  question: string;
  type: string;
  difficulty: string;
  level: string;
  chapter_id: string;
  answers: any;
}

const QuestionsBank: React.FC = () => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | 'examData' | 'upload' | "show" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedData, setSelectedData] = useState<any>(null);
  const [questionType, setQuestionType] = useState<string>("");
  const [difficultyLevel, setDifficultyLevel] = useState<string>("");
  const currentLang = useSelector((state: any) => state?.user?.currentLang);
  const navigate = useNavigate();
  const truncateText = (text: string, maxLength: number) =>
    text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload' | 'show', id?: React.Key) => {
    setSelectedRowId(id ?? null);
    setModalAction(action);
    setModalVisible(true);

    if (action === 'add') {
      navigate('/add/questions');
    } else if (action === 'update') {
      navigate('/update/questions');
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleRadioChange = ({ questionType, difficultyLevel }: { questionType: string; difficultyLevel: string }) => {
    setQuestionType(questionType);
    setDifficultyLevel(difficultyLevel);
  };

  const values = {
    mode: "question_bank",
    type: "get",
    question_type: questionType,
    difficulty: difficultyLevel,
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
  };

  const deleteValue = {
    mode: "exam",
    type: "delete",
    id: selectedRowId
  };

  const { data: listQuestionsBank, isLoading, isFetching } = useQuery(
    [queryKeys.listQuestionsBank, values.language_id, searchTerm, questionType, difficultyLevel],
    () => FetchData({ ...values, name: searchTerm }),
    { keepPreviousData: true }
  );

  const decodedResponse = decodeResponse(listQuestionsBank);
  const decryptedQuestionsBank = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['level', 'difficulty', 'type', 'answers', 'chapter'];
    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    const decryptedAnswers = item.answers?.map((answer: any) => {
      const decryptedData = decryptItem({ data: answer.answer });
      return {
        ...answer,
        answer: decryptedData.data,
      };
    });

    itemWithoutDecryption.answers = decryptedAnswers;

    return itemWithoutDecryption;
  });

  const formattedData = decryptedQuestionsBank?.map((record: any) => ({
    key: record.id,
    question: record.question,
    type: record.type,
    difficulty: record?.difficulty,
    level: record.level,
    chapter: record.chapter,
    chapter_id: record.chapter_id,
    certificate: record.level.certificate.name,
    answers: record.answers,
    id: record.id
  }));
  const type = currentLang === 'ar' ? roleLabelsAr : roleLabelsEn;

  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id='question' />,
      dataIndex: 'question',
      render: (text: string) => {
        return truncateText(decodeURIComponent(text), 40);
      },
    },
    {
      title: <FormattedMessage id='type' />,
      dataIndex: 'type',
      render: (roleValue: string) => {
        const roleLabel = type.find((item) => item.value === roleValue)?.label || roleValue;
        return roleLabel;
      },
    },
    {
      title: <FormattedMessage id='difficulty' />,
      dataIndex: 'difficulty',
    },
    {
      key: 'operation',
      width: 100,
      render: (record: DataType) => (
        <Space>
          <EyeOutlined key="view" className='text-gray-400' onClick={() => { setSelectedData(record); showModal("show"); }} />
          <Link to="/update/questions" className='text-gray-400' state={{ question_data: record }}><EditOutlined /></Link>
          <DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', record.id)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_question' />}
        tabName={<FormattedMessage id='questions_bank' />}
        onClick={() => showModal('add')}
        items={[{ title: <FormattedMessage id='question_bank' /> }]}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(value)}
        onRadioChange={handleRadioChange}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"question_bank"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listQuestionsBank} />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'exam'} />}
        queryKey={queryKeys.listExams}
        onClose={closeModal}
      />

      <CustomExpandedTable
        columns={columns}
        data={formattedData}
        isLoading={isLoading}
        isFetching={isFetching}
        scroll={{ x: 'max-content' }}
        expandableData={{
          expandRowByClick: true,
          expandedRowRender: (record: {
            answers: any;
            is_correct: any;
            type: any;
          }) =>
            <div className="p-0">
              <Row className='bg-[#FAFAFA] p-3 mb-2'>
                {record?.answers?.map((answer: any, index: any) => (
                  <Col span={24} className='px-3 text-[#0E103A] text-[16px] font-extrabold'>
                    <FormattedMessage id={answer.is_correct === 1 ? 'rightanswer' : `answer ${index + 1}`} />:
                    <span className='text-[#8C8C8C]'>
                      <br />
                      {answer?.answer}
                    </span>
                  </Col>
                ))}
              </Row>
            </div>
          ,
          expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: () => void; record: any }) => (
            <span onClick={onExpand}>{expanded ? <UpOutlined /> : <DownOutlined />}</span>
          ),
          rowExpandable: (record: { id: string; }) => record.id !== 'Not Expandable',
        }}
      />

      <Modal
        open={isModalVisible && modalAction === 'show'}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={[]}
        onCancel={closeModal}
        width={850}
      >
        <Card className='m-4'>
          {selectedData && (
            <div>
              <Row className='bg-[#F5F5F5] p-2 mb-4'>
                <FormattedMessage id={'question'} />
              </Row>
              <Row>
                <Card className='w-full mb-2'>{selectedData?.question}</Card>
              </Row>
              <Row gutter={10}>
                {selectedData?.answers?.map((answer: any, index: any) => (
                  <Col xl={12} className='mb-4'>
                    <div className={answer.is_correct === 1 ? 'bg-[#0E103A] p-2 mb-4 text-white' : 'bg-[#F5F5F5] p-2 mb-4'}>
                      <FormattedMessage id={answer?.is_correct === 1 ? 'rightanswer' : `answer ${index + 1}`} />:
                    </div>
                    <Card>{answer?.answer}</Card>
                  </Col>
                ))}
              </Row>

              <Row gutter={12}>
                <Col span={12} className='bg-[#F5F5F5] p-2 mb-4'> <FormattedMessage id='difficulty' />: {selectedData?.difficulty}</Col>
                <Col span={12} className='bg-[#F5F5F5] p-2 mb-4'><FormattedMessage id='level' />: {selectedData?.level?.name}</Col>
              </Row>
            </div>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default QuestionsBank;
import React, { useState } from 'react';
import { Empty, Flex, Pagination, Space, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptData, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { EyeOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const ListExams: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const currentLang = useSelector((state: any) => state?.user?.currentLang);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;
  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;

  const values = {
    mode: "exam_monitoring",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
  };

  const { data: listLiveExams, isLoading, isFetching } = useQuery(
    [queryKeys.listLiveExams, values.language_id, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listLiveExams);
  const decryptedData = decodedResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['status', 'scheduled_at', 'exam_configuration'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const currentItems: any[] = decryptedData?.slice(indexOfFirstItem, indexOfLastItem);

  const columns: ColumnsType<any> = [
    {
      title: <FormattedMessage id={'scheduled_at'} />,
      dataIndex: 'scheduled_at',
      width: 200,
    },
    {
      title: <FormattedMessage id={'status'} />,
      dataIndex: 'status',
      width: 200,
    },
    {
      title: <FormattedMessage id={'exam_configuration'} />,
      render: (record) => {
        const decryptedTitle = decryptData(record?.exam_configuration?.exam_title);
        return <span>{decryptedTitle}</span>;
      },
    },
    {
      key: 'operation',
      width: 100,
      render: (record: any) => (
        <div className='text-center'>
          <Space >
            <Link to="/exam/data" className='text-gray-400 mx-1' state={{ exam_configuration: record }}><EyeOutlined /></Link>
          </Space>
        </div>
      ),
    },
  ];

  const renderExamMonitoring = () => {
    if (isLoading || isFetching) {
      return (
        <Flex gap="middle" vertical className='mt-40'>
          <Spin tip={<FormattedMessage id='loading' />} size="large">
            <div className='spin-loading' />
          </Spin>
        </Flex>
      );
    }

    if (!currentItems || currentItems?.length === 0) {
      return <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />;
    }

    return (
      <>
        {currentItems?.map((item) => (
          <div key={item?.id} className='mb-5 custom-table'>
            <Table
              bordered
              dataSource={[item]}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
          </div>
        ))}
        <Flex justify='end'>
          <Pagination
            current={currentPage}
            total={currentItems?.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </Flex>
      </>
    );
  };

  return (
    <>
      <FilterTable
        items={[{ title: <FormattedMessage id='exams' /> }]}
        isUpload={false}
        isAdded={false}
        onSearch={(value) => setSearchTerm(value)}
        isFilter={false}
      />

      {renderExamMonitoring()}
    </>
  );
};

export default ListExams;